let pageWidth = window.outerWidth;
if(pageWidth > 567) {
    window.addEventListener('scroll', function () {
        let scrollTop = window.pageYOffset;
        if (scrollTop >= 100) {
            document.querySelector('#header').classList.add('header__scroll');
        } else {
            document.querySelector('#header').classList.remove('header__scroll');
        }
    });
}

/* подсветка пунктов меню */

//console.log(window.location.pathname);
if(window.location.pathname == '/'){
    let sections = document.querySelectorAll("section");
    window.addEventListener('scroll', function () {
        let scrollDistance = window.scrollY;
        //console.log(scrollDistance);

        sections.forEach((element, index) => {
            //console.log(element.offsetTop);

            if((element.offsetTop + element.clientHeight - document.querySelector("#header").clientHeight) <= scrollDistance){
                document.querySelectorAll(".anchors").forEach((el) => {
                    if(el.classList.contains("active")){
                        el.classList.remove("active");
                    }
                });

                document.querySelectorAll(".header__navs ul li")[index].querySelector(".anchors").classList.add("active");

            }


        });
    });
}




let galleryItems = document.querySelectorAll(".gallery__item");

galleryItems.forEach((item) => {
   item.addEventListener("click", () => {
       console.log(item.getAttribute("data-url"));
   });
});

/* бургер меню */

let burger = document.querySelector("#burger"),
    headerNavs = document.querySelector(".header__navs");

burger.addEventListener("click", () => {
    burger.classList.toggle("header__burger_btn-active");

    headerNavs.style.top = (document.querySelector("#header").clientHeight - 10) + 'px';
    headerNavs.classList.toggle("header__navs-active");
});

/* плавный скролл */
const anchors = document.querySelectorAll(".anchors");
anchors.forEach((item) => {
    item.addEventListener('click', function (e) {
        e.preventDefault();
        const blockID = item.getAttribute('href').substr(1);
        document.getElementById(blockID).scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
        anchors.forEach((i) => {
            i.classList.remove("active");
        });
        item.classList.add("active");

        burger.classList.remove("header__burger_btn-active");
        headerNavs.classList.remove("header__navs-active");
    })
});




$(document).ready(function () {
    /* отзывы */
    $('.reviews__slider').slick({
        arrows: true,
        dots: true,
        /* для адаптивной высоты */
        adaptiveHeight: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 1000,
        easing: 'ease',
        infinite: true,
        initialSlide: 0,
        autoplay: false,
        autoplaySpeed: 9000,
        pauseOnFocus: true,
        pauseOnHover: true,
        pauseOnDotsHover: true,
        draggable: true,
        swipe: true,
        variableWidth: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    arrows: false
                }
            }
        ]
    });
});
